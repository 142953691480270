// File src/components/ DateInput.js
import React, { useState, useEffect, useContext } from 'react';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import FormLabel from '@mui/joy/FormLabel';
import { CryptoContext } from '../CryptoContext';


export default function DateInput({ name, defaultValue }) {
    const { state, dispatch } = useContext(CryptoContext);
    const [startDate, setStartDate] = useState(state.startDate);
    const [endDate, setEndDate] = useState(state.endDate);

    useEffect(() => {
        setStartDate(state.startDate);
        setEndDate(state.endDate);
    }, [state.startDate, state.endDate]);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleInputChange = (event) => {

        const value = event.target.value;
        const timestamp = new Date(value).getTime();
        if (name === "Start date") {
            dispatch({ type: 'SET_START_DATE', payload: timestamp });
            setStartDate(timestamp);
        } else if (name === "End date") {
            dispatch({ type: 'SET_END_DATE', payload: timestamp });
            setEndDate(timestamp);
        }

    };

    return (
        <Stack spacing={1.5} sx={{ width: '100%' }}>
            <FormLabel>{name}</FormLabel>
            <Input
                type="date"
                data-testid={name === "Start date" ? "start-date-input" : "end-date-input"}
                slotProps={{
                    input: {
                        min: name === "End date" && startDate ? formatDate(startDate) : '2018-06-07',
                        max: name === "Start date" && endDate ? formatDate(endDate) : '2024-02-14',
                    },
                }}

                defaultValue={defaultValue ? formatDate(defaultValue) : ''}
                onChange={handleInputChange}
            />
        </Stack>
    );
}
