import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Stack from '@mui/joy/Stack';
import FormLabel from '@mui/joy/FormLabel';
import { useMediaQuery } from '@mui/material';
import { CryptoContext } from '../CryptoContext';

export default function PriceInput() {
    const { state, dispatch } = useContext(CryptoContext);
    const [currency, setCurrency] = useState('dollar');
    const isMediumScreen = useMediaQuery('(max-width: 960px)');

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (newValue !== null && newValue !== undefined) {
            dispatch({ type: 'SET_AMOUNT', payload: newValue });
        }
    };

    return (
        <Stack spacing={1.5} sx={{ width: '100%' }}>
            <FormLabel>Amount</FormLabel>
            <Input
                placeholder="Amount"
                value={state.amount}
                onChange={handleChange}
                startDecorator={{ dollar: '$', baht: '฿', yen: '¥' }[currency]}
                endDecorator={
                    !isMediumScreen && (
                        <React.Fragment>
                            <Divider orientation="vertical" />
                            <Select
                                variant="plain"
                                value={currency}
                                onChange={(_, value) => {
                                    setCurrency(value);
                                }}
                                slotProps={{
                                    listbox: {
                                        variant: 'outlined',
                                    },
                                }}
                                sx={{ mr: -1.5, '&:hover': { bgcolor: 'transparent' } }}
                            >
                                <Option value="dollar">US dollar</Option>
                                <Option value="baht">Thai baht</Option>
                                <Option value="yen">Japanese yen</Option>
                            </Select>
                        </React.Fragment>
                    )
                }
            />
        </Stack>
    );
}
