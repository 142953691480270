import React, { useContext } from "react";
import Avatar from '@mui/joy/Avatar';
import AvatarGroup from '@mui/joy/AvatarGroup';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Stack from '@mui/joy/Stack';
import Divider from '@mui/material/Divider';
import Grid from '@mui/joy/Grid';
import FormLabel from '@mui/joy/FormLabel';

import { CryptoContext } from '../CryptoContext';


export default function CardExchanges({ exchange }) {
    const { state, dispatch } = useContext(CryptoContext);
    const infoExchanges = state.infoExchanges.find(ex => ex.id === exchange.id);

    return (
        <Card
            variant="outlined"
            sx={{
                width: "100%",
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                // overflow: 'auto',
                marginTop: "20px",
            }}
        >
            <Grid container sx={{ width: '100%', flexWrap: 'nowrap' }}>
                <Grid item xs={9} sx={{ pr: 2, display: 'flex', flexDirection: 'column' }}>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={`https://cryptodca.io/img/${exchange.img}`}
                            alt="Lettuce Icon"
                            style={{ marginRight: 8, width: 100, height: "auto", marginBottom: 10 }}
                        />

                    </Box>
                    <CardContent>
                        <Typography level="body-sm">
                            Coinbase is an American company that operates a cryptocurrency exchange platform. It was founded in June 2012 by Brian Armstrong. Coinbase has over 50 million verified users and is one of the largest cryptocurrency platforms worldwide.
                        </Typography>
                    </CardContent>

                    <CardActions>
                        <Stack direction="column" spacing={2}>
                            <Typography id="Payment methods" level="body-sm" fontWeight="lg" mb={1} sx={{ fontSize: "12px" }}>
                                Payment methods
                            </Typography>
                            <div role="group" aria-labelledby="Payment methods">
                                <List size="sm">
                                    <Stack direction="row" spacing={1}>
                                        <ListItem>
                                            <img
                                                src="https://cryptodca.io/img/icons/sepa_logo.png"
                                                alt="Sepa Payment Icon"
                                                style={{ marginRight: 8, width: "auto", height: 15 }}
                                            />

                                        </ListItem>
                                        <ListItem>
                                            <img
                                                src="https://cryptodca.io/img/icons/mastercard_visa_logo.png"
                                                alt="Creditcard Payment Icon"
                                                style={{ marginRight: 8, width: "auto", height: 15 }}
                                            />

                                        </ListItem>
                                    </Stack>
                                </List>
                            </div>
                        </Stack>


                    </CardActions>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>

                    <Typography id="Payment methods" level="body-sm" fontWeight="lg" ml={2} >
                        Available Coins
                    </Typography>

                    <Box
                        height={100}
                        width={"100%"}
                        // my={4}
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                        gap={2}
                        p={2}
                        sx={{
                            // border: '1px solid rgba(0, 0, 0, 0.2)',

                            flexWrap: 'wrap',
                        }}
                    >
                        {infoExchanges.coins.map((coin) => (
                            <img
                                key={coin}
                                src={`https://cryptodca.io/img/coins/${coin}.png`}
                                alt={coin}
                                style={{ width: 22, height: 22 }}
                            />
                        ))}
                    </Box>
                    <Box width={"100%"} display="flex" justifyContent="center" mt={2}>
                        <Button variant="solid" color="primary">
                            Visit
                        </Button>
                    </Box>
                </Grid>


            </Grid>
        </Card>
    );
}