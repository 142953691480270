import { Grid, Typography, CssVarsProvider } from '@mui/joy';
import { styled } from '@mui/system';
import Footer from '../Footer';


const StyledUl = styled('ul')(({ theme }) => ({
    listStyleType: 'circle',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

const StyledLi = styled('li')(({ theme }) => ({
    marginBottom: theme.spacing(3.5),
}));

export default function OpenBetaPage() {
    return (
        <CssVarsProvider>


            <Grid container spacing={2}>
                <Grid item xs={3}></Grid>
                <Grid item xs={6} container>
                    <Grid container spacing={5}>
                        <Grid xs={12}>
                            <Typography level="h1" component="div" sx={{ textAlign: 'left', mt: 16, mb: 5, fontSize: '3.0rem' }}>
                                Open Beta: The DCA App is live!
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <img src="https://cryptodca.io/content/en/blog/app-open-beta/cryptodca-app-early-access-version.jpeg" style={{ width: '100%', height: 'auto' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography level="h5" component="div" sx={{ textAlign: 'left' }}>
                                We are thrilled to share that our DCA App is now in public Beta version! You are now able to create a free account and your first DCA plan.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography level="h5" component="div" sx={{ textAlign: 'left' }}>
                                We created a video on YouTube explaining the benefits and current functionalities of the app. You will also find a section in the video on how to create an account and connect your API key with it.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography level="h5" component="div" sx={{ textAlign: 'left' }}>
                                We want to point out that this is not a final product yet and bugs and errors could occur. The design is not final as well and will be improved. However, it is working and we invite you to use it and provide us feedback via contact@cryptodca.io.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <img src="https://cryptodca.io/content/en/blog/app-open-beta/cryptodca-app-early-access-version.jpeg" style={{ width: '80%', height: 'auto' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography level="h2" component="div" sx={{ textAlign: 'left' }}>
                                App Functionality (as of now)
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Typography level="h5" component="div" sx={{ textAlign: 'left', marginBottom: 3 }}>
                                Right now you will be able to do the following with the DCA app:
                            </Typography>
                            <StyledUl>
                                <StyledLi>Create an account with email and password</StyledLi>
                                <StyledLi>Add Kraken API keys</StyledLi>
                                <StyledLi>Create custom DCA buying plans
                                    <StyledUl>
                                        <StyledLi>Select coins (BTC / ETH)</StyledLi>
                                        <StyledLi>Select base currency (EUR / USD)</StyledLi>
                                        <StyledLi>Select start / end date</StyledLi>
                                        <StyledLi>Select frequency (daily, weekly, bi-weekly, monthly)</StyledLi>
                                    </StyledUl>
                                </StyledLi>
                                <StyledLi>Delete custom DCA buying plans</StyledLi>
                                <StyledLi>Monitor transaction and transaction status of created DCA plan</StyledLi>
                            </StyledUl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography level="h5" component="div" sx={{ textAlign: 'left' }}>
                                You need to make sure to add the Kraken API key and have enough funding on your Kraken reference account (on the day of execution). Currently, we do not take any additional fees and you will only pay the low Kraken Pro fee of 0,40% as of now.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography level="h5" component="div" sx={{ textAlign: 'left' }}>
                                Create a free account with the invite code: CryptoSpring2024
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}></Grid>
            </Grid>
            <Footer />
        </CssVarsProvider>
    );
}
