//File src/Home.js

import React, { useState, useEffect, useContext } from "react";
import { CssVarsProvider } from "@mui/joy/styles"

import axios from 'axios';
import Grid from '@mui/joy/Grid';
import { getApiUrl } from "./components/apiUrl";
import Chart from "./components/home/Chart"
import TableDCA from "./components/home/TableDCA"
import CardInverted from "./components/home/CardInverted"
import UserInputForm from "./components/home/UserInputForm"
import BoxInfo from "./components/home/BoxInfo"
import { Typography, Button, Sheet, Link } from '@mui/joy';
import Questions from "./components/home/Questions"
import Footer from "./components/Footer";
import { CryptoContext } from './components/CryptoContext';

export default function Home() {
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useContext(CryptoContext);
  const { selectedId, amount, frequency, startDate, endDate, filteredPriceData, coinOptions, processedData, infoExchanges, allCoin, checkCredit } = state;
  const [priceData, setPriceData] = useState(null);
  const [valueTopBar, setvalueTopBar] = useState([1667754000000, 2600.53, 3660]);



  useEffect(() => {
    localStorage.setItem('startDate', startDate);
    localStorage.setItem('endDate', endDate);
    localStorage.setItem('amount', amount);
    localStorage.setItem('frequency', JSON.stringify(frequency));
    localStorage.setItem('selectedId', JSON.stringify(selectedId));
  }, [startDate, endDate, amount, frequency, selectedId]);


  useEffect(() => {
    const apiUrl = getApiUrl('/api/crypto');
    axios.get(apiUrl)
      .then(response => {
        dispatch({ type: 'SET_ALL_COIN', payload: response.data });
      })
      .catch(error => {
        console.error('Error fetching all coins:', error);
      });
  }, [loading, dispatch]);




  useEffect(() => {
    if (loading) {
      const fetchCoinIdsAndNames = async () => {
        try {
          const apiUrl = getApiUrl('/api/crypto/ids-and-names');
          const response = await axios.get(apiUrl);
          dispatch({
            type: 'SET_COIN_OPTIONS',
            payload: response.data.map(({ id, name, image }) => ({
              id,
              title: name,
              image
            }))
          });
          setLoading(false);
        } catch (error) {
          console.error('Error fetching coin IDs and names:', error);
        }
      };
      fetchCoinIdsAndNames();
    }
  }, [loading, dispatch]);



  useEffect(() => {
    if (loading || !coinOptions.length) return;
    const fetchPriceData = async () => {
      if (selectedId && selectedId.image) {
        const imageIdMatch = selectedId.image.match(/images\/(\d+)\//);
        const imageId = imageIdMatch ? imageIdMatch[1] : null;

        if (!imageId) {
          console.error('Invalid image ID extracted from image URL.');
          return;
        }

        try {
          const apiUrl = getApiUrl(`/api/new-price?imageId=${imageId}`);
          const response = await axios.get(apiUrl);
          setPriceData(response.data);
        } catch (error) {
          console.error('Error fetching price data:', error);
        }
      }
    };
    fetchPriceData();
  }, [selectedId, loading, coinOptions]);


  useEffect(() => {
    if (!priceData || !startDate || !endDate || !frequency) return;

    const filterByFrequency = (entry) => {
      const entryDate = new Date(entry[0]);
      const daysDifference = (entryDate - startDate) / (1000 * 60 * 60 * 24);

      switch (frequency.title) {
        case "Weekly":
          return entryDate.getDay() === 1;
        case "Monthly":
          return entryDate.getDate() === 1;
        case "Biweekly":
          return daysDifference % 14 === 0;
        default:
          return true;
      }
    };

    const filteredData = priceData.filter(entry => {
      const entryTimestamp = entry[0];
      return entryTimestamp >= startDate && entryTimestamp <= endDate;
    }).filter(filterByFrequency);

    dispatch({ type: 'SET_FILTERED_PRICE_DATA', payload: filteredData });
  }, [startDate, endDate, priceData, frequency]);




  useEffect(() => {
    if (processedData && processedData.length > 0) {
      const lastElement = processedData[processedData.length - 1];

      setvalueTopBar(lastElement);
    }
  }, [processedData]);



  // console.log("startDate", startDate);
  // console.log("endDate", endDate);
  // console.log("amount", amount);
  // console.log("selectedId", state.testSelectedId);
  //  console.log("frequency", frequency);
  // console.log("filteredPriceData", filteredPriceData);
  console.log("coinOptions  ", coinOptions)
  //  console.log('Processed Data:', processedData);
  // console.log("allCoin:", allCoin)
  // console.log("processedData:", processedData);
  // console.log("infoExchanges:", infoExchanges);
  // console.log("checkCredit:", checkCredit);




  return (

    <CssVarsProvider disableTransitionOnChange >


      <Grid container spacing={2} justifyContent="center">
        <Grid xs={12}>
          <Typography level="h1" component="div" sx={{ textAlign: 'center', mt: 12 }}>
            Crypto DCA Calculator
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center">
        <Grid xs={12}>
          <Typography level="h6" component="div" sx={{ textAlign: 'center' }}>
            Historic DCA performance of buying Bitcoin (BTC) monthly with 100 US Dollar for the last 1095 days.
          </Typography>
        </Grid>
      </Grid>




      <Grid container spacing={1} sx={{ flexGrow: 1, marginTop: 2, marginBottom: 4 }}>
        <Grid item xs={2} />

        <Grid container xs={8} >

          <Grid container spacing={1} sx={{
            flexGrow: 1,
            marginTop: 2,
            marginBottom: 4,
          }}>
            <Grid item xs={2}>
            </Grid>

            <Grid container xs={8} spacing={2} >
              <Grid item style={{ flexGrow: 1 }}>
                <CardInverted name={"Money invested"} valueTopBar={valueTopBar[2]} />
              </Grid>

              <Grid item style={{ flexGrow: 1 }}>
                <CardInverted name={"Portfolio performance"} valueTopBar={(((valueTopBar[1] - valueTopBar[2]) / valueTopBar[2]) * 100).toFixed(2)} />
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <CardInverted name={"Current profit"} valueTopBar={(valueTopBar[1] - valueTopBar[2]).toFixed(2)} />
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <CardInverted name={"Portfolio value"} valueTopBar={valueTopBar[1]} />
              </Grid>

            </Grid>
            <Grid item xs={2}>
            </Grid>

          </Grid>


          <Grid container spacing={4} sx={{ flexGrow: 1, padding: 2 }}>
            <Grid item xs={3} sx={{ paddingRight: 6 }}>
              <UserInputForm defaultStart={startDate} defaultEnd={endDate} />
            </Grid>
            <Grid container xs={9}>
              <Chart chartData={processedData} />
            </Grid>
          </Grid>

          <Grid container spacing={4} sx={{ flexGrow: 1, padding: 2 }}>
            <Grid item xs={12} lg={3} sx={{ paddingRight: { md: 6 } }}>
              <BoxInfo />
            </Grid>
            <Grid item xs={12} lg={9}>
              <TableDCA />
            </Grid>
          </Grid>

        </Grid>


        <Grid item xs={2} />
      </Grid>

      <Grid container spacing={2} justifyContent="center">
        <Grid xs={12}>
          <Typography level="h2" component="div" sx={{ textAlign: 'center' }}>
            Frequently-asked questions
          </Typography>
        </Grid>
      </Grid>

      <Questions question={"Disadvantages of Dollar Cost Averaging"} answer={"When using the average cost effect, you give the opportunity to be speculative in the market. You lose the ability to buy large amounts of the crypto asset at the “bottom” or at the “top”. Additionally, building up a larger sum can take time, which may not be beneficial in a bear market."} />
      <Questions question={"How to use the savings plan calculator?"} answer={"You have to choose a cryptocurrency, the amount in US dollars or euros, the frequency of buying the coin and the total time period. You receive a historical performance calculation for the purchase of the asset with the chosen parameters."} />
      <Questions question={"How is the portfolio value calculated?"} answer={"At every purchase you add a certain amount of the asset to your portfolio. The amount fluctuates depending on the asset's price on that specific date. At the end of the graph, respectively today, you can see how the asset has performed during the selected time frame."} />
      <Questions question={"What does the gray line say?"} answer={"The gray line represents your opportunity cost of not purchasing the asset but instead leaving the money without interest e.g. on a bank account. If you do not see the gray line your crypto savings plan has performed better than holding cash. But if you see it, holding cash was the better option on that day."} />
      <Footer />
    </CssVarsProvider>
  )
}
