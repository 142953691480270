//File src/App.js
import { Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import Guide from './components/guide/Guide';
import Exchanges from './components/exchanges/Exchanges';
import Header from './components/Header';
import OpenBetaPage from './components/guide/OpenBetaPage'
import BinanceAuto from './components/guide/BinanceAuto'
import BinpandaAuto from './components/guide/BinpandaAuto'

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/exchanges" element={<Exchanges />} />
        <Route path="/guide/open-beta" element={<OpenBetaPage />} />
        <Route path="/guide/binance-auto" element={<BinanceAuto />} />
        <Route path="/guide/binpanda-auto" element={<BinpandaAuto />} />
      </Routes>
    </div>
  );
}

export default App;
