// File src/components/Frequency.js
import React, { useContext } from 'react';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Autocomplete from '@mui/joy/Autocomplete';
import { CryptoContext } from '../CryptoContext';

export default function Frequency() {
    const { state, dispatch } = useContext(CryptoContext);

    const handleOptionChange = (event, value) => {
        dispatch({ type: 'SET_FREQUENCY', payload: value });
    };

    return (
        <FormControl id="grouped-demo" sx={{ width: '100%' }}>
            <FormLabel>Frequency</FormLabel>
            <Autocomplete
                options={[
                    { title: 'Daily' },
                    { title: 'Weekly' },
                    { title: 'Biweekly' },
                    { title: 'Monthly' }
                ]}
                value={state.frequency}
                getOptionLabel={(option) => option.title}
                onChange={handleOptionChange}
                isOptionEqualToValue={(option, value) => option.title === value.title}
            />
        </FormControl>
    );
}   