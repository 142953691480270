import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography, Button, Sheet, Link } from '@mui/joy';
import { CssVarsProvider } from "@mui/joy/styles"
import Box from '@mui/joy/Box';
import CountrySelect from './CountrySelect';
import FormLabel from '@mui/joy/FormLabel';
import CheckBox from './CheckBox';
import CardExchanges from './CardExchanges';
import { CryptoContext } from '../CryptoContext';
import axios from 'axios';
import { getApiUrl } from "../apiUrl";




export default function Exchanges() {
    const { state, dispatch } = useContext(CryptoContext);
    const { infoExchanges, checkCredit, checkPaypal, checkSepa, checkCustodial, checkNonCustodial, checkBitcoinNetwork } = state;
    const [filteredExchanges, setFilteredExchanges] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const apiUrl = getApiUrl('/api/exchanges');
        axios.get(apiUrl)
            .then(response => {
                dispatch({ type: 'SET_ALL_EXCHANGES', payload: response.data });
            })
            .catch(error => {
                console.error('Error fetching info exchanges:', error);
            });
    }, [loading, dispatch]);


    useEffect(() => {
        const filterExchanges = () => {
            return infoExchanges.filter(exchange => {
                const options = exchange.purchaseOptions || [];
                const walletType = exchange.walletType || '';

                let matchesPurchaseOptions = true;
                if (checkCredit) matchesPurchaseOptions = matchesPurchaseOptions && options.includes('creditcard');
                if (checkPaypal) matchesPurchaseOptions = matchesPurchaseOptions && options.includes('paypal');
                if (checkSepa) matchesPurchaseOptions = matchesPurchaseOptions && options.includes('sepa');
                if (checkBitcoinNetwork) matchesPurchaseOptions = matchesPurchaseOptions && options.includes('bitcoin-lightning-network');


                let matchesWalletType = true;
                if (checkCustodial) matchesWalletType = matchesWalletType && walletType.includes('custodial');
                if (checkNonCustodial) matchesWalletType = matchesWalletType && walletType.includes('non-custodial');


                return matchesPurchaseOptions && matchesWalletType;
            });
        };


        if (checkCredit || checkPaypal || checkSepa || checkBitcoinNetwork || checkCustodial || checkNonCustodial) {
            setFilteredExchanges(filterExchanges());
        } else {

            setFilteredExchanges(infoExchanges);
        }
    }, [infoExchanges, checkCredit, checkPaypal, checkSepa, checkBitcoinNetwork, checkCustodial, checkNonCustodial]);

    return (
        <CssVarsProvider>


            <Grid container spacing={2} justifyContent="center">
                <Grid xs={12}>
                    <Typography level="h1" component="div" sx={{ textAlign: 'center', mt: 12 }}>
                        Best Crypto Exchanges for DCA
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography level="h5" component="div" sx={{ textAlign: 'center' }}>
                        Filter and sort the best Crypto Exchanges for your Crypto Savings Plan
                    </Typography>

                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={2}>

                </Grid>
                <Grid item spacing={2} container xs={8} >

                    <Grid item xs={4}>
                        <Box
                            height="100%"
                            maxWidth={300}
                            maxHeight={450}
                            width="100%"
                            my={2}
                            display="flex"
                            flexDirection="column"
                            alignItems="stretch"
                            gap={4}
                            p={2}
                            sx={{
                                border: '1px solid rgba(0, 0, 0, 0.2)',
                                flexWrap: 'wrap',
                                transition: 'all 0.3s ease',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            }}>

                            <CountrySelect />
                            <CheckBox />
                        </Box>
                    </Grid>
                    <Grid item xs={8} spacing={2}>
                        {filteredExchanges.map(exchange => (
                            <CardExchanges key={exchange.id} exchange={exchange} />
                        ))}
                    </Grid>

                </Grid>
                <Grid item xs={2}>

                </Grid>
            </Grid>

        </CssVarsProvider>

    );
}
