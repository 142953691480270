import * as React from 'react';
import { Grid, Typography, Button, Sheet } from '@mui/joy';
import Header from '../Header';
import { CssVarsProvider } from "@mui/joy/styles"
import Box from '@mui/joy/Box';
import { Link } from 'react-router-dom';


function CustomBox({ imgSrc, imgAlt, title, subtitle, to }) {
    return (
        <Box
            height="100%"
            maxHeight={350}
            width="100%"
            my={2}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap={4}
            p={2}
            sx={{
                border: '1px solid rgba(0, 0, 0, 0.2)',
                flexWrap: 'wrap',
                transition: 'all 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.02)',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                }
            }}
        >

            <Link to={to} style={{ textDecoration: 'none' }}>
                <img src={imgSrc} alt={imgAlt} style={{ width: '100%', height: 200 }} />
            </Link>
            <Typography level="h5" component="div" sx={{ textAlign: 'left' }}>
                {title}
            </Typography>
            <Typography level="h6" component="div" sx={{ textAlign: 'left' }}>
                {subtitle}
            </Typography>
        </Box>
    );
}


export default function Guide() {

    return (
        <CssVarsProvider>


            <Grid container spacing={2} justifyContent="center">
                <Grid xs={12}>
                    <Typography level="h1" component="div" sx={{ textAlign: 'center', mt: 12 }}>
                        Guide
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={2}>

                </Grid>
                <Grid item spacing={10} container xs={8}>
                    <Grid item xs={4}>
                        <CustomBox
                            imgSrc="https://cryptodca.io/content/en/blog/app-open-beta/cryptodca-app-early-access-version.jpeg"
                            imgAlt="Description of DCA App Early Access"
                            title="Open Beta: The DCA App is live!"
                            subtitle="DCA App is now in public Beta version! You are now able to create a free account and your first DCA plan"
                            to="/guide/open-beta"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CustomBox
                            imgSrc="https://cryptodca.io/content/en/blog/binance-auto-invest/binance-auto-invest.jpg"
                            imgAlt="Description of Binance Auto-Invest"
                            title="How to create a Crypto Savings Plan with Binance Auto-Invest"
                            subtitle="Tutorial - Create a Binance Auto-Invest plan Step by Step"
                            to="/guide/binance-auto"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CustomBox
                            imgSrc="https://cryptodca.io/content/en/blog/bitpanda-auto-invest/bitpanda-autoinvest-thumbnail.jpg"
                            imgAlt="Description of Bitpanda Auto-Invest"
                            title="How to create a Crypto Savings Plan with Bitpanda Auto-Invest"
                            subtitle="Tutorial - Create a Bitpanda Auto-Invest plan Step by Step"
                            to="/guide/binpanda-auto"
                        />
                    </Grid>

                </Grid>
                <Grid item xs={2}>

                </Grid>
            </Grid>


            <Grid container spacing={2}>
                <Grid item xs={2}>

                </Grid>
                <Grid item spacing={10} container xs={8}>
                    <Grid item xs={4}>
                        <CustomBox
                            imgSrc="https://cryptodca.io/content/en/blog/coinbase-tutorial/coinbase-tutorial.jpg"
                            imgAlt="Description of DCA App Early Access"
                            title="Open Beta: The DCA App is live!"
                            subtitle="DCA App is now in public Beta version! You are now able to create a free account and your first DCA plan"
                            to="/guide/open-beta"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CustomBox
                            imgSrc="https://cryptodca.io/content/en/blog/blockchance/logo.jpg"
                            imgAlt="Description of Binance Auto-Invest"
                            title="How to create a Crypto Savings Plan with Binance Auto-Invest"
                            subtitle="Tutorial - Create a Binance Auto-Invest plan Step by Step"
                            to="/guide/binance-auto"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CustomBox
                            imgSrc="https://cryptodca.io/content/en/blog/dca-indicator/dca-indicator.jpeg"
                            imgAlt="Description of Bitpanda Auto-Invest"
                            title="How to create a Crypto Savings Plan with Bitpanda Auto-Invest"
                            subtitle="Tutorial - Create a Bitpanda Auto-Invest plan Step by Step"
                            to="/guide/binpanda-auto"
                        />
                    </Grid>

                </Grid>
                <Grid item xs={2}>

                </Grid>
            </Grid>




        </CssVarsProvider>

    );
}
