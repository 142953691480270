import React, { useState, useEffect, useContext } from 'react';
import Table from '@mui/joy/Table';
import { CryptoContext } from '../CryptoContext';
import ProcessData from './ProcessData';


const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    // Determines which page numbers to show
    const paginationItems = Array.from({ length: totalPages }, (_, i) => i + 1)
        .filter(page => page === 1 || page === totalPages || (page >= currentPage - 1 && page <= currentPage + 1));

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>

            <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
            {paginationItems.map(page =>
                page === currentPage ? (
                    <button key={page} style={{ fontWeight: 'bold', backgroundColor: 'green', color: 'white' }}>{page}</button>
                ) : (
                    <button key={page} onClick={() => onPageChange(page)} style={{ margin: '0 8px' }}>{page}</button>
                )
            )}
            {currentPage + 1 < totalPages && <span>...</span>}
            <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>

        </div>
    );
};


export default function TableDCA() {
    const { state, dispatch } = useContext(CryptoContext);
    const { amount } = state;
    const { filteredPriceData } = state;
    const [processedRows, setProcessedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 20;

    useEffect(() => {
        const processedData = ProcessData(filteredPriceData, amount);
        console.log("filteredPriceData", filteredPriceData)
        console.log(processedData);
        setProcessedRows(processedData);

        const formattedData = processedData.map(row => [
            new Date(row.date).getTime(), // Timestamp
            parseFloat(row.portfolioValue), // Portfolio Value
            parseFloat(row.moneyInvested) // Money Invested
        ]);

        dispatch({ type: 'SET_PROCESSED_DATA', payload: formattedData });
    }, [filteredPriceData, amount]);

    if (!filteredPriceData || filteredPriceData.length === 0) {
        return null;
    }

    // Pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    let currentRows = processedRows.slice(indexOfFirstRow, indexOfLastRow);

    // Fill in missing rows with placeholder data if there are fewer than 20 rows
    const emptyRows = rowsPerPage - currentRows.length;
    if (emptyRows > 0) {
        currentRows = currentRows.concat(Array(emptyRows).fill({
            date: "-",
            bitcoinPrice: "-",
            moneyInvested: "-",
            portfolioValue: "-",
            changeSum: "-",
            changePercentage: "-"
        }));
    }

    const totalPages = Math.ceil(processedRows.length / rowsPerPage);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    return (
        <div>
            <Table sx={{ '& thead th:nth-child(1)': { width: '20%' } }}>
                <thead>
                    <tr>
                        <th>DATE</th>
                        <th>BITCOIN PRICE</th>
                        <th>MONEY INVESTED</th>
                        <th>PORTFOLIO VALUE</th>
                        <th>CHANGE Σ</th>
                        <th>CHANGE %</th>
                    </tr>
                </thead>
                <tbody>
                    {currentRows.length > 0 ? currentRows.map((row, index) => (
                        <tr key={index}>
                            <td>{row.date}</td>
                            <td>{row.bitcoinPrice}</td>
                            <td>{row.moneyInvested}</td>
                            <td>{row.portfolioValue}</td>
                            <td style={{ color: row.changeSum >= 0 ? 'green' : 'red' }}>{row.changeSum}</td>
                            <td style={{ color: row.changePercentage >= 0 ? 'green' : 'red' }}>{row.changePercentage}</td>
                        </tr>
                    )) : <tr><td colSpan="6">No data available</td></tr>}
                </tbody>
            </Table>
            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
        </div>
    );
}
