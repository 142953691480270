import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

export default function CardInverted({ name, valueTopBar }) {

    const getImageSrc = (name) => {
        switch (name) {
            case "Money invested":
                return "/image/topBar/money.png";
            case "Portfolio performance":
                if (valueTopBar > 0) {
                    return "/image/topBar/trend.png";
                } else {
                    return "/image/topBar/decrease.png";
                }
            case "Current profit":

                if (valueTopBar > 0) {
                    return "/image/topBar/graph (1).png";
                } else {
                    return "/image/topBar/graph.png";
                }
            case "Portfolio value":
                return "/image/topBar/wallet (2).png";

            default: return "";
        }
    };

    return (
        <Card variant="solid" color="" invertedColors sx={{ border: '1px solid #ccc' }} >
            <CardContent orientation="horizontal">
                <Box
                    component="img"
                    sx={{
                        width: 30,
                        height: 30,
                        margin: 1,
                    }}
                    src={getImageSrc(name)}
                    alt={name}
                />

                <CardContent>
                    <Typography level="h2" sx={{ fontSize: '18px' }}>{valueTopBar}</Typography>
                    <Typography level="body-md" sx={{ fontSize: '12px' }}>{name}</Typography>
                </CardContent>
            </CardContent>
        </Card>
    );
}
