import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { CryptoContext } from '../CryptoContext';


function Chart({ chartData }) {

    useEffect(() => {
        if (!chartData || chartData.length === 0) {
            return;
        }

        var root = am5.Root.new("chartdiv");
        root.setThemes([am5themes_Animated.new(root)]);

        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true
            })
        );

        chart.get("colors").set("step", 5);

        var cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
                behavior: "none"
            })
        );
        cursor.lineY.set("visible", false);

        var xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: "day", count: 1 },
                renderer: am5xy.AxisRendererX.new(root, {
                    minorGridEnabled: true,
                    minGridDistance: 70
                }),
                tooltip: am5.Tooltip.new(root, {})
            })
        );

        var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        var series1 = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: "PORTFOLIO VALUE",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "open",
                valueXField: "date",
                stroke: root.interfaceColors.get("positive"),
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{valueY}"
                })
            })
        );

        series1.fills.template.setAll({
            visible: true,
            fillOpacity: 0.5,
        });

        var gradient = am5.LinearGradient.new(root, {
            stops: [{
                color: root.interfaceColors.get("positive"),
                offset: 0
            },
            {
                color: root.interfaceColors.get("positive"),
                offset: 1,
                opacity: 0
            }
            ],
            rotation: 90
        });

        series1.fills.template.set("fillGradient", gradient);





        var bullet1 = series1.bullets.push(function () {
            return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                    radius: 2,
                    fill: root.interfaceColors.get("positive"),
                })
            });
        });

        var series2 = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: "MONEY INVESTED",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "close",
                valueXField: "date",
                stroke: root.interfaceColors.get("negative"),
                fill: root.interfaceColors.get("negative"),
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{valueY}"
                })
            })
        );

        var bullet2 = series2.bullets.push(function () {
            return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                    radius: 1,
                    fill: root.interfaceColors.get("negative"),
                })
            });
        });

        var legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            marginTop: 15,
            marginBottom: 15,
        }));
        legend.data.setAll(chart.series.values);

        // scrollbarX
        var scrollbarX = am5.Scrollbar.new(root, {
            orientation: "horizontal",
            visible: false // Ẩn thanh cuộn
        });

        // scrollbarX for chart 
        chart.bottomAxesContainer.children.push(scrollbarX);


        const { times, values1, values2 } = extractTimeAndValues(chartData);

        series1.data.setAll(times.map((time, index) => ({ date: time, open: values1[index] })));
        series2.data.setAll(times.map((time, index) => ({ date: time, close: values2[index] })));

        return () => {
            root.dispose();
        };
    }, [chartData]);

    function extractTimeAndValues(chartData) {
        if (!chartData || chartData.length === 0) {
            return { times: [], values1: [], values2: [] };
        }

        const times = [];
        const values1 = [];
        const values2 = [];

        chartData.forEach(dataPoint => {
            if (Array.isArray(dataPoint) && dataPoint.length === 3) {
                const time = dataPoint[0];
                const value1 = dataPoint[1];
                const value2 = dataPoint[2];
                times.push(time);
                values1.push(value1);
                values2.push(value2);
            }
        });

        return { times, values1, values2 };
    }

    return (
        <div id="chartdiv" style={{ width: "100%", height: "100%" }}></div>
    );
}

export default Chart;
