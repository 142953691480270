import { Grid, Typography, CssVarsProvider } from '@mui/joy';
import { styled } from '@mui/system';
import Footer from '../Footer';

const StyledUl = styled('ul')(({ theme }) => ({
    listStyleType: 'circle',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

const StyledLi = styled('li')(({ theme }) => ({
    marginBottom: theme.spacing(2.5),
    '& a': {
        textDecoration: 'none',
        color: 'inherit'
    }
}));

const StepTitle = ({ title, id }) => (
    <>
        <Grid item xs={12} id={id}>
            <Typography level="h2" component="div" sx={{ textAlign: 'left' }}>
                {title}
            </Typography>
        </Grid>

    </>
);

const StepContent = ({ content }) => (
    <>
        <Grid item xs={12}>
            <Typography level="h5" component="div" sx={{ textAlign: 'left' }}>
                {content}
            </Typography>
        </Grid>

    </>
);
export default function BinpandaAuto() {
    return (
        <CssVarsProvider>

            <Grid container spacing={2}>
                <Grid item xs={3}></Grid>
                <Grid item xs={6} container>
                    <Grid container spacing={5}>
                        <Grid xs={12}>
                            <Typography level="h1" component="div" sx={{ textAlign: 'left', mt: 16, mb: 5, fontSize: '3.0rem' }}>
                                How to create a Crypto Savings Plan with Bitpanda Auto-Invest
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <img src="https://cryptodca.io/content/en/blog/bitpanda-auto-invest/bitpanda-autoinvest-thumbnail.jpg"
                                style={{ width: '100%', height: '300px', objectFit: 'cover', objectPosition: 'center' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography level="h4" component="div" sx={{ textAlign: 'left' }}>
                                Content
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledUl>
                                <StyledLi><a href="#what-is-binance-auto-invest">What is Binance Auto-Invest?</a></StyledLi>
                                <StyledLi><a href="#create-account">Step 1. Create a free account on Binance</a></StyledLi>
                                <StyledLi><a href="#navigate-auto-invest">Step 2. Navigate to the Binance Auto-Invest page</a></StyledLi>
                                <StyledLi><a href="#choose-crypto">Step 3. Choose a cryptocurrency and time frame</a></StyledLi>
                                <StyledLi><a href="#savings-plan-settings">Step 4. Choose savings plan settings</a></StyledLi>
                                <StyledLi><a href="#add-currency">Step 5. Add enough base currency before the plan is executed</a></StyledLi>
                                <StyledLi><a href="#faq">FAQ</a></StyledLi>
                            </StyledUl>
                        </Grid>

                        <StepContent
                            content="In this article, I'll show you how to create a crypto savings plan with Binance Auto-Invest."
                        />


                        <StepTitle id="what-is-binance-auto-invest" title="What is Binance Auto-Invest?" />
                        <StepContent
                            content="Binance Auto-Invest gives you the opportunity to invest in different cryptocurrencies on a regular and long-term basis. You can automatically invest daily, weekly, bi-weekly or monthly with any amount in the different cryptocurrencies that Binance offers."
                        />
                        <StepContent
                            content="With Binance you benefit from low fees, a large number of cryptocurrencies and a simple user interface."
                        />
                        <StepContent
                            content="With Binance you benefit from low fees, a large number of cryptocurrencies and a simple user interface."
                        />
                        <StepContent
                            content="To use this feature, you need a free Binance account and a USD stablecoin (USDT or BUSD)."
                        />

                        <StepTitle
                            title=""
                            id=""
                        />
                        <StepContent content="" />


                        <StepTitle
                            title="Step 1. Create a free account on Binance"
                            id="create-account"
                        />
                        <Grid item xs={12}>
                            <img src="https://cryptodca.io/content/en/blog/bitpanda-auto-invest/step1-1.webp"
                                style={{ width: '100%', height: '250px', objectFit: 'cover', objectPosition: 'center' }} />
                        </Grid>
                        <StepContent
                            content="If you don't already have an account with Binance, you can create one for free at Binance.com or through the mobile app. Binance is available on the web, iOS or Android."
                        />

                        <StepTitle
                            title="Step 2. Navigate to the Binance Auto-Invest page"
                            id="navigate-auto-invest"
                        />
                        <Grid item xs={12}>
                            <img src="https://cryptodca.io/content/en/blog/bitpanda-auto-invest/step2-1.webp"
                                style={{ width: '100%', height: 'auto', objectFit: 'cover', objectPosition: 'center' }} />
                        </Grid>
                        <StepContent content={`Navigate to "Auto-Invest" in the menu under "Earn". You will find a list of over 60 cryptocurrencies that you can invest in.`} />

                        <StepTitle
                            title="Step 3. Choose a cryptocurrency and time frame"
                            id="choose-crypto"
                        />
                        <Grid item xs={12}>
                            <img src="https://cryptodca.io/content/en/blog/bitpanda-auto-invest/step3-1.webp"
                                style={{ width: '100%', height: 'auto', objectFit: 'cover', objectPosition: 'center' }} />
                        </Grid>
                        <StepContent content="Choose a cryptocurrencies and a time period you want to invest in the cryptocurrencies. You can choose between 3 years and 7 days. You can manually delete or edit the savings plan at any time after that." />


                        <StepTitle
                            title="Step 4. Choose savings plan settings"
                            id="savings-plan-settings"
                        />
                        <Grid item xs={12}>
                            <img src="https://cryptodca.io/content/en/blog/bitpanda-auto-invest/step4-1.webp"
                                style={{ width: '100%', height: 'auto', objectFit: 'cover', objectPosition: 'center' }} />
                        </Grid>
                        <StepContent content={`Choose your savings plan settings. "Buy with credit card" is not recommended due to the high fees. Use USDT or BUSD instead. If you do not have them, see the next step.`} />

                        <StepTitle
                            title="Step 5. Add enough base currency before the plan is executed"
                            id="add-currency"
                        />
                        <Grid item xs={12}>
                            <img src="https://cryptodca.io/content/en/blog/bitpanda-auto-invest/step5-1.webp"
                                style={{ width: '100%', height: 'auto', objectFit: 'cover', objectPosition: 'center' }} />
                        </Grid>
                        <StepContent content="In order to execute the savings plan cheaply, you currently need a USD stablecoin (USDT or BUSD). USDT is the largest stablecoin and BUSD is Binance's own USD stablecoin." />

                        <StepTitle
                            title="FAQ"
                            id="faq"
                        />

                        <StepTitle
                            title="Can I have more than one Savings plan in Bitpanda??"
                        />
                        <StepContent content="In order to cancel or delete a savings plan, you need to go to your profile and select “Savings plan”. This will open a new window with all the savings plans you have created." />

                        <StepTitle
                            title=""
                            id=""
                        />

                        <StepTitle
                            title="How can I cancel/delete a Bitpanda Savings plan?"
                            id=""
                        />

                        <StepContent content={'In order to cancel or delete a savings plan, you need to go to your profile and select “Savings plan”. This will open a new window with all the savings plans you have created. Click the savings plank you want to cancel/delete and click the button “Cancel plan”. This will open a confirmation popup that you need to accept in order to cancel the savings plan.'} />
                        <StepContent content="" />

                    </Grid>
                </Grid>
                <Grid item xs={3}>

                </Grid>

            </Grid>
            <Grid>
                <Grid item xs={12}>
                    <Footer />
                </Grid>
            </Grid>

        </CssVarsProvider>
    );
}
