import React, { useContext } from "react";
import Checkbox from '@mui/joy/Checkbox';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Typography from '@mui/joy/Typography';
import FormLabel from '@mui/joy/FormLabel';
import { CryptoContext } from '../CryptoContext';


export default function CheckBox() {
    const { state, dispatch } = useContext(CryptoContext);

    const handleCheckboxChange = (paymentType, event) => {
        // console.log(paymentType);
        dispatch({
            type: `SET_${paymentType}`,
            payload: event.target.checked,

        });
    };
    return (
        <div>

            <FormLabel >
                <Typography id="Payment methods" level="body-sm" fontWeight="lg" mb={1}>
                    Payment methods
                </Typography>
            </FormLabel>
            <div role="group" aria-labelledby="Payment methods">
                <List size="sm">
                    <ListItem>
                        <Checkbox
                            checked={state.checkSepa}
                            onChange={(event) => { handleCheckboxChange('SEPA', event) }}
                            label={
                                <>
                                    <img
                                        src="https://cryptodca.io/img/icons/sepa_logo.png"
                                        alt="Lettuce Icon"
                                        style={{ marginRight: 8, width: "auto", height: 15 }}
                                    />
                                    Sepa Payment
                                </>
                            }

                        />
                    </ListItem>
                    <ListItem>
                        <Checkbox
                            checked={state.checkCredit}
                            onChange={(event) => { handleCheckboxChange('CREDIT', event) }}
                            label={
                                <>
                                    <img
                                        src="https://cryptodca.io/img/icons/mastercard_visa_logo.png"
                                        alt="Lettuce Icon"
                                        style={{ marginRight: 8, width: "auto", height: 15 }}
                                    />
                                    Credit Card
                                </>
                            } />
                    </ListItem>
                    <ListItem>
                        <Checkbox
                            checked={state.checkPaypal}

                            onChange={(event) => { handleCheckboxChange('PAYPAL', event) }}

                            label={
                                <>
                                    <img
                                        src="https://cryptodca.io/img/icons/paypal_logo.png"
                                        alt="Lettuce Icon"
                                        style={{ marginRight: 8, width: "auto", height: 15 }}
                                    />
                                    Paypal
                                </>
                            } />
                    </ListItem>
                </List>
            </div>


            <FormLabel >
                <Typography id="Wallet Type" level="body-sm" fontWeight="lg" mb={1}>
                    Wallet Type
                </Typography>
            </FormLabel>
            <List size="sm">
                <ListItem>
                    <Checkbox
                        checked={state.checkCustodial}
                        onChange={(event) => { handleCheckboxChange('CUSTODIAL', event) }}
                        label={
                            <>
                                <img
                                    src="https://cryptodca.io/img/icons/custodial_logo.png"
                                    alt="Lettuce Icon"
                                    style={{ marginRight: 8, width: "auto", height: 15 }}
                                />
                                Custodial
                            </>
                        }

                    />
                </ListItem>
                <ListItem>
                    <Checkbox
                        checked={state.checkNonCustodial}
                        onChange={(event) => { handleCheckboxChange('NONCUSTODIAL', event) }}
                        label={
                            <>
                                <img
                                    src="https://cryptodca.io/img/icons/non_custodial_logo.png"
                                    alt="Lettuce Icon"
                                    style={{ marginRight: 8, width: "auto", height: 15 }}
                                />
                                Non-Custodial
                            </>
                        } />
                </ListItem>
            </List>

            <FormLabel >
                <Typography id="Wallet Type" level="body-sm" fontWeight="lg" mb={1}>
                    Features
                </Typography>
            </FormLabel>
            <List size="sm">
                <ListItem>
                    <Checkbox
                        checked={state.checkBitcoinNetwork}
                        onChange={(event) => { handleCheckboxChange('BITCOIN', event) }}
                        label={
                            <>
                                <img
                                    src="https://cryptodca.io/img/icons/bitcoin-lightning-network_logo.png"
                                    alt="Lettuce Icon"
                                    style={{ marginRight: 8, width: "auto", height: 15 }}
                                />
                                Bitcoin Lightning Network
                            </>
                        }

                    />
                </ListItem>
            </List>
        </div>
    );
}