//File src/components/guide.js
import * as React from 'react';
import Box from '@mui/joy/Box';
import ListInput from './ListInput';
import PriceInput from './PriceInput';
import DateInput from './DateInput';
import Frequency from './Frequency';

export default function UserInputForm({ defaultStart, defaultEnd }) {
    return (
        <Box
            height={500}
            width={"100%"}
            my={4}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap={4}
            p={2}
            sx={{
                border: '1px solid rgba(0, 0, 0, 0.2)',
                flexWrap: 'wrap',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}
        >
            <ListInput />
            <PriceInput />
            <Frequency />
            <DateInput name={"Start date"} defaultValue={defaultStart} />
            <DateInput name={"End date"} defaultValue={defaultEnd} />
        </Box>
    );
}
