import * as React from 'react';
import { Grid, Typography, Sheet, Button } from '@mui/joy';
import { Link } from 'react-router-dom';


export default function Footer() {
    return (
        <Sheet sx={{ py: 2 }}>
            <Grid container spacing={2} sx={{ backgroundColor: 'ButtonFace' }}>
                <Grid item xs={1}>
                    {/* Empty grid item for spacing */}
                </Grid>
                <Grid item xs={2} container spacing={2} justifyContent="flex-start" sx={{ textAlign: 'left' }}>
                    <Grid item xs={12}>
                        <Typography level="h3" component="div">
                            cryptoDCA
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography level="h6" component="div">
                            contact@cryptodca.io
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography level="h5" component="div">
                            Get Infos about our upcoming App!
                        </Typography>
                        <Button variant="solid" color="neutral">
                            Subscribe
                        </Button>
                    </Grid>
                </Grid>


                <Grid item xs={1}>
                    {/* Empty grid item for spacing */}
                </Grid>
                <Grid item xs={2} justifyContent="flex-start" sx={{ textAlign: 'left' }}>
                    <Grid item xs={12}>
                        <Typography level="h6" component="div" fontWeight="bold">
                            Navigation
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            level="h6"
                            component={Link}
                            to="/guide"
                            variant="plain"
                            sx={{
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            Exchanges
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            level="h6"
                            component={Link}
                            to="/guide"
                            variant="plain"
                            sx={{
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            Guide
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    {/* If you have more content, it goes here */}
                </Grid>
                <Grid item xs={2} container justifyContent="flex-start" sx={{ textAlign: 'left' }}>
                    <Grid item xs={12}>
                        <Typography level="h6" component="div" fontWeight="bold">
                            DCA Calculator
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={2}>
                    {/* If you have more content, it goes here */}
                </Grid>
            </Grid>
        </Sheet>
    );
}
