// File src/components/ListInput.js
import React, { useState, useEffect, useContext } from 'react';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Autocomplete from '@mui/joy/Autocomplete';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import { CryptoContext } from '../CryptoContext';


export default function ListInput() {
    const { state, dispatch } = useContext(CryptoContext);
    const { coinOptions } = state;
    const handleOptionChange = (event, value) => {

        if (value !== null && value !== undefined) {
            dispatch({ type: 'SET_SELECTED_ID', payload: value });
        }
    };
    return (
        <FormControl id="grouped-demo" sx={{ width: '100%' }}>
            <FormLabel>Coin</FormLabel>
            <Autocomplete
                options={coinOptions}
                value={state.selectedId}
                getOptionLabel={(option) => option.title}
                onChange={handleOptionChange}
                renderOption={(props, option) => (
                    <ListItem
                        {...props}
                        sx={{
                            cursor: 'default',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                        }}
                    >
                        <ListItemDecorator>
                            <img src={option.image} alt={option.title} style={{ width: 24, height: 24, marginRight: 8 }} />
                        </ListItemDecorator>
                        <ListItemContent>{option.title}</ListItemContent>
                    </ListItem>
                )}
            />
        </FormControl>
    );
}
