import React, { useContext } from "react";
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import Divider from '@mui/joy/Divider';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import { CryptoContext } from '../CryptoContext';

export default function BoxInfo() {
    const { state } = useContext(CryptoContext);
    const { allCoin, selectedId } = state;



    if (!allCoin || allCoin.length === 0) {
        return (
            <Box
                height={500}
                width={"100%"}
                my={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap={4}
                p={2}
                sx={{
                    border: '1px solid rgba(0, 0, 0, 0.2)',
                    flexWrap: 'wrap',

                }}
            >
                <Typography level="h4">Loading coins data...</Typography>
            </Box>
        );
    }


    const selectedCoin = allCoin.find(coin => coin.id === selectedId.id);


    if (!selectedCoin) {
        return (
            <Box
                height={500}
                width={"100%"}
                my={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap={4}
                p={2}
                sx={{
                    border: '1px solid rgba(0, 0, 0, 0.2)',
                    flexWrap: 'wrap',

                }}
            >
                <Typography level="h4">Coin not found</Typography>
            </Box>
        );
    }


    return (
        <Box
            height={500}
            width={"100%"}
            my={4}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap={1}
            p={2}
            sx={{
                border: '1px solid rgba(0, 0, 0, 0.2)',
                flexWrap: 'wrap',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}
        >

            <List
                sx={{
                    '--ListItemDecorator-size': '26px',
                    // backgroundColor: "ButtonFace",
                    // maxWidth: 360,
                    maxHeight: 40,
                    // paddingBottom: 1,
                    // display: 'inline-flex',
                }}
            >
                <ListItem>
                    <ListItemDecorator>
                        <img src={selectedCoin.image} alt={selectedCoin.title} style={{ width: 24, height: 24, marginRight: 8 }} />
                    </ListItemDecorator>
                    <ListItemContent>
                        <Typography level="title-sm">{selectedCoin.name}</Typography>
                    </ListItemContent>
                </ListItem>
            </List>

            <Typography sx={{
                fontWeight: 'bold',
                fontSize: '2.4rem',
                // backgroundColor: "ButtonFace",
            }}>
                ${selectedCoin.current_price.toLocaleString()}
            </Typography>

            <Divider variant="middle" />

            <Typography sx={{
                // fontWeight: 'bold',
                fontSize: '0.9rem',
                // backgroundColor: "ButtonFace",
            }}>
                <span style={{ color: 'gray' }}>Market Cap:</span> ${selectedCoin.market_cap.toLocaleString()}
            </Typography>

            <Divider variant="middle" />

            <Typography sx={{ fontSize: '0.9rem', }}>
                <span style={{ color: 'gray' }}>Market Cap Rank:</span> #{selectedCoin.market_cap_rank}
            </Typography>

            <Divider variant="middle" />

            <Typography sx={{
                fontSize: '0.9rem',
            }}>
                <span style={{ color: 'gray' }}>Total Supply:</span> {selectedCoin.total_supply}
            </Typography>

            <Divider variant="middle" />

            <Typography sx={{
                fontSize: '0.9rem',
            }}>
                <span style={{ color: 'gray' }}>Max Supply:</span> {selectedCoin.max_supply}
            </Typography>

            <Divider variant="middle" />

            <Typography sx={{
                fontSize: '0.9rem',
            }}>
                <span style={{ color: 'gray' }}>High 24h:</span> {selectedCoin.high_24h}
            </Typography>

            <Divider variant="middle" />

            <Typography sx={{
                fontSize: '0.9rem',
            }}>
                <span style={{ color: 'gray' }}>Low 24h:</span> {selectedCoin.low_24h}
            </Typography>
        </Box>
    );
}
