//File src/components/CryptoContext.js
import React, { createContext, useReducer } from 'react';


const getInitialState = () => {
    const defaultState = {
        startDate: 1636243200000,
        endDate: 1667779200000,
        amount: 10,
        frequency: { title: "Daily" },
        selectedId: {
            id: 'bitcoin',
            title: 'Bitcoin',
            image: 'https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1696501400'
        },
        coinOptions: [],
        priceData: null,
        filteredPriceData: null,
        processedData: null,
        valueTopBar: [1667754000000, 2600.53, 3660],
        allCoin: [],
        infoExchanges: [],
        checkPaypal: false,
        checkSepa: false,
        checkCredit: false,
        checkCustodial: false,
        checkNonCustodial: false,
        checkBitcoinNetwork: false,
    };

    return {
        ...defaultState,
        startDate: Number(localStorage.getItem('startDate')) || defaultState.startDate,
        endDate: Number(localStorage.getItem('endDate')) || defaultState.endDate,
        amount: Number(localStorage.getItem('amount')) || defaultState.amount,
        frequency: JSON.parse(localStorage.getItem('frequency') || JSON.stringify(defaultState.frequency)),
        selectedId: JSON.parse(localStorage.getItem('selectedId') || JSON.stringify(defaultState.selectedId)),
        coinOptions: [],
        priceData: null,
        filteredPriceData: null,
        processedData: null,
        valueTopBar: [1667754000000, 2600.53, 3660],
        allCoin: [],
        infoExchanges: [],
        checkPaypal: false,
        checkSepa: false,
        checkCredit: false,
        checkCustodial: false,
        checkNonCustodial: false,
        checkBitcoinNetwork: false,

    };
};

const initialState = getInitialState();

const cryptoReducer = (state, action) => {
    switch (action.type) {
        case 'SET_START_DATE':
            return { ...state, startDate: action.payload };
        case 'SET_END_DATE':
            return { ...state, endDate: action.payload };
        case 'SET_AMOUNT':
            return { ...state, amount: action.payload };
        case 'SET_FREQUENCY':
            return { ...state, frequency: action.payload };
        case 'SET_SELECTED_ID':
            return { ...state, selectedId: action.payload };
        case 'SET_COIN_OPTIONS':
            return { ...state, coinOptions: action.payload };
        case 'SET_PRICE_DATA':
            return { ...state, priceData: action.payload };
        case 'SET_FILTERED_PRICE_DATA':
            return { ...state, filteredPriceData: action.payload };
        case 'SET_PROCESSED_DATA':
            return { ...state, processedData: action.payload };
        case 'SET_VALUE_TOP_BAR':
            return { ...state, valueTopBar: action.payload };
        case 'SET_ALL_COIN':
            return { ...state, allCoin: action.payload };
        case 'SET_ALL_EXCHANGES':
            return { ...state, infoExchanges: action.payload };
        case 'SET_CREDIT':
            return { ...state, checkCredit: action.payload };
        case 'SET_PAYPAL':
            return { ...state, checkPaypal: action.payload };
        case 'SET_SEPA':
            return { ...state, checkSepa: action.payload };
        case 'SET_CUSTODIAL':
            return { ...state, checkCustodial: action.payload };
        case 'SET_NONCUSTODIAL':
            return { ...state, checkNonCustodial: action.payload };
        case 'SET_BITCOIN':
            return { ...state, checkBitcoinNetwork: action.payload };
        default:
            return state;
    }
};


export const CryptoContext = createContext({});


export const CryptoProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cryptoReducer, initialState);

    return (
        <CryptoContext.Provider value={{ state, dispatch }}>
            {children}
        </CryptoContext.Provider>
    );
};
