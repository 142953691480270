import * as React from 'react';
import { Grid, Typography, Sheet } from '@mui/joy';

export default function Questions({ question, answer }) { // Sử dụng destructuring để nhận props
    return (
        <Sheet
            sx={{
                py: 2,
            }}
        >
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={6}>
                    <Typography level="h4" component="div" sx={{ textAlign: 'left' }}>
                        {question}
                    </Typography>
                </Grid>
                <Grid item xs={3}>

                </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={6}>
                    <Typography level="body1" component="div" sx={{ textAlign: 'left' }}>
                        {answer}
                    </Typography>
                </Grid>
                <Grid item xs={3}>

                </Grid>
            </Grid>
        </Sheet>
    );
}
