import * as React from 'react';
import { Grid, Typography, Button, Sheet } from '@mui/joy';
import LanguageIcon from '@mui/icons-material/Language';
import { Link } from 'react-router-dom';

export default function Header() {
    return (
        <Sheet
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1000,
                backgroundColor: 'background.default',
                py: 2,
                opacity: 0.9,
                height: 60,
            }}
        >
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                <Grid item xs={12} sm={4} container justifyContent="center" alignItems="center" sx={{ height: 80 }}>
                    <img src="/1675.png" alt="cryptoDCA Logo" style={{ height: 90 }} />
                </Grid>

                <Grid item xs={false} sm={2} />
                <Grid item xs={12} sm={4} container spacing={2} justifyContent="center">
                    <Grid item>
                        <Button component={Link} to="/" variant="plain">
                            Home
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button component={Link} to="/guide" variant="plain">
                            Guide
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button component={Link} to="/exchanges" variant="plain">
                            Exchanges
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={2} container justifyContent="flex-end" sx={{ flexWrap: 'nowrap' }}>
                    <Button variant="plain" startDecorator={<LanguageIcon />} sx={{ marginRight: 2 }}>
                        US
                    </Button>
                    <Button component={Link} to="/" variant="solid" color="primary" sx={{ marginRight: 2 }}>
                        Crypto DCA Calculator
                    </Button>
                </Grid>
                <Grid item xs={false} sm={2} />
            </Grid>
        </Sheet>
    );
}
