

export default function ProcessData(filteredPriceData, amount) {
    if (!filteredPriceData || filteredPriceData.length === 0) {
        return [];
    }

    let moneyInvested = 0;
    let portfolioValue = 0;
    let countPortfolioValue = 0;
    let changeSum = 0;
    let changePercentage = 0;
    const processedData = filteredPriceData.map(item => {
        const timestamp = item[0];
        const bitcoinPrice = parseFloat(item[1]).toFixed(2);
        const date = new Date(timestamp).toLocaleDateString();
        moneyInvested += parseInt(amount);
        portfolioValue = ((countPortfolioValue + (amount / bitcoinPrice)) * bitcoinPrice).toFixed(2);
        countPortfolioValue += (amount / bitcoinPrice);
        changeSum = (portfolioValue - moneyInvested).toFixed(2);
        changePercentage = (((portfolioValue - moneyInvested) / moneyInvested) * 100).toFixed(2);
        return {
            date,
            timestamp,
            bitcoinPrice,
            moneyInvested,
            portfolioValue,
            changeSum,
            changePercentage
        };
    });
    return processedData;
}
